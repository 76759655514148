var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(!_vm.loading)?_c('div',{staticClass:"is-flex is-flex-wrap-wrap is-align-items-center my-4"},[_c('h1',{staticClass:"mr-5"},[_c('span',[_vm._v(_vm._s(_vm.$tc("partner", 2)))])])]):_vm._e(),(_vm.partner)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content pt-3"},[_c('BackButton',{attrs:{"parent-route":{ name: 'PartnerIndex' }}}),_c('table',{staticClass:"table details-table"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$tc("partner_role")))]),_c('td',[_vm._v(_vm._s(_vm.partner.partnerRole.name))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$tc("company")))]),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'CompanyView',
                params: { id: _vm.partner.company.id },
              }}},[_vm._v(" "+_vm._s(_vm.partner.company.name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$tc("user")))]),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'UserView',
                params: { id: _vm.partner.user.id },
              }}},[_vm._v(" "+_vm._s(_vm.partner.user.name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$tc("contract")))]),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'ContractView',
                params: { id: _vm.partner.contract.id },
              }}},[_vm._v(" "+_vm._s(_vm.partner.contract.company.name)+" ("+_vm._s(_vm._f("date")(_vm.partner.contract.startDate))+" – "+_vm._s(_vm._f("date")(_vm.partner.contract.endDate))+") ")])],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("commission_percentage")))]),_c('td',[_vm._v(_vm._s(_vm._f("percentage")(_vm.partner.commissionPercentage)))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("commission_fee")))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.partner.commissionFee)))])])])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }