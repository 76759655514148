<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("partner", 2) }}</span>
      </h1>
    </div>

    <div v-if="partner" class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'PartnerIndex' }"></BackButton>

        <table class="table details-table">
          <tr>
            <th>{{ $tc("partner_role") }}</th>
            <td>{{ partner.partnerRole.name }}</td>
          </tr>
          <tr>
            <th>{{ $tc("company") }}</th>
            <td>
              <router-link
                :to="{
                  name: 'CompanyView',
                  params: { id: partner.company.id },
                }"
              >
                {{ partner.company.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("user") }}</th>
            <td>
              <router-link
                :to="{
                  name: 'UserView',
                  params: { id: partner.user.id },
                }"
              >
                {{ partner.user.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("contract") }}</th>
            <td>
              <router-link
                :to="{
                  name: 'ContractView',
                  params: { id: partner.contract.id },
                }"
              >
                {{ partner.contract.company.name }}
                ({{ partner.contract.startDate | date }} &ndash;
                {{ partner.contract.endDate | date }})
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t("commission_percentage") }}</th>
            <td>{{ partner.commissionPercentage | percentage }}</td>
          </tr>
          <tr>
            <th>{{ $t("commission_fee") }}</th>
            <td>{{ partner.commissionFee | money }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";

export default {
  name: "PartnerView",
  components: { BackButton },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      partner: null,
    };
  },
  computed: {
    ...mapGetters(["findPartnerRoleByUri"]),
  },
  mounted() {
    this.getPartner(this.id).then(async (partner) => {
      partner.partnerRole = this.findPartnerRoleByUri(partner.partnerRole);
      this.partner = partner;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getPartner"]),
  },
};
</script>
